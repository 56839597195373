<template>
    <div v-if="active" class="dialog-overlay">
      <div class="dialog-content">
        <h3>Motif d'annulation</h3>
        <textarea v-model="motif" placeholder="Entrez le motif ici..." rows="4"></textarea>
        <div class="actions">
          <button @click="closeDialog">Annuler</button>
          <button @click="submitMotif">Valider</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      active: Boolean,
    },
    data() {
      return {
        motif: "",
      };
    },
    methods: {
      submitMotif() {
        this.$emit("submit", this.motif);
        this.closeDialog();
      },
      closeDialog() {
        this.$emit("close");
      },
    },
  };
  </script>
  
  <style scoped>
  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dialog-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
  }
  
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  </style>
  