<template>
    <div :data-id="item.id" :class="{'parent': true, 'danger': item.quantite === 0}">
        <span class="name" style="flex-grow: 1;">{{ item.nom }}</span>
        <div class="prix">
            <b>{{ money(item.prix_vente) }} Fbu</b> <span>| dispo:</span>
            <span class="dispo">
                {{ item.quantite || 0 }} {{ item.unite_sortante }}
            </span>
        </div>
        <div class="buttons">
            <button 
                class="bg-blue" 
                @click="checkValidation('decrease')" 
                :disabled="qtt === 0"
            >-</button>
            <input 
                v-if="editable" 
                type="number" 
                class="item_qtt"
                v-model="qtt" 
                @keydown.stop 
                :max="item.quantite" 
                min="0"
            >
            <label v-else @click="checkValidation('edit')">{{ qtt }}</label>
            <button 
                class="bg-blue" 
                @click="checkValidation('increase')" 
                :disabled="item.quantite === 0"
            >+</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        item: { 
            type: Object, 
            required: true 
        }
    },
    data() {
        return {
            cart: this.$store.state.cart, 
            editable: false,
        };
    },
    methods: {
        async checkValidation(action) {
            if (action === 'decrease' && this.qtt === 0) {
                return; // Do not decrease if quantity is already 0
            }

            try {
                let kiosk_id = this.getActiveKiosk().id;
                let link = `${this.url}/stock/?produit__kiosk=${kiosk_id}&produit__id=${this.item.id}`;
                let response = await axios.get(link, this.headers);
                let stocks = response.data.results;

                if (!stocks || stocks.length === 0) {
                    alert("Aucun stock trouvé pour ce produit.");
                    return;
                }

                let invalidStock = stocks.find(stock => stock.validated_by === null);
                if (invalidStock) {
                    alert("Veuillez valider tous les stocks associés à ce produit avant de continuer.");
                    return;
                }

                if (action === 'decrease') {
                    this.decreaseFromStocks(stocks);
                } else if (action === 'increase') {
                    this.increaseQtt();
                } else if (action === 'edit') {
                    this.enableEdition();
                }
            } catch (error) {
                this.displayErrorOrRefreshToken(error, () => this.checkValidation(action));
            }
        },
        increaseQtt() {
            this.cart.add(this.item);
            this.$set(this.item, 'quantite', this.item.quantite - 1);
        },
        decreaseFromStocks(stocks) {
            let remainingQuantity = 1;
            let updatedStocks = [];

            for (let stock of stocks) {
                if (remainingQuantity <= 0) break;

                if (stock.quantite >= remainingQuantity) {
                    stock.quantite -= remainingQuantity;
                    remainingQuantity = 0;
                } else {
                    remainingQuantity -= stock.quantite;
                    stock.quantite = 0;
                }

                updatedStocks.push(stock);
            }

            if (remainingQuantity > 0) {
                alert("Quantité insuffisante dans les stocks disponibles.");
            } else {
                this.$store.commit('updateStocks', updatedStocks);
                this.cart.decrease(this.item.id);
                this.$set(this.item, 'quantite', this.item.quantite + 1);
            }
        },
        enableEdition() {
            if (this.item.quantite !== 0) this.editable = true;
        }
    },
    computed: {
        qtt: {
            get() {
                return this.cart.getQuantite(this.item.id);
            },
            set(value) {
                if (value.length === 0) return;
                this.cart.add(this.item, value);
            }
        }
    }
};
</script>

<style scoped>
* {
    color: #333;
}
.img {
    padding: 5px;
    color: #aaa;
}
.danger * {
    color: #eb5766;
    cursor: not-allowed;
}
.danger .bg-blue {
    background-color: #eb5766;
    color: white;
    cursor: not-allowed;
}
.danger .bg-blue:hover {
    background-color: #eb5766;
    color: white;
}
.parent {
    background-color: #f8f9fa;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    height: 100%;
    max-height: 150px;
}
.name {
    font-size: 1em;
    font-weight: 700;
    width: 100%;
    color: #333;
}
.prix {
    font-size: 0.9em;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 4px 0;
    color: #333;
}
.qtt {
    font-size: 0.8em;
    margin-bottom: 2px;
}
.buttons {
    display: flex;
    margin-top: 2px;
    width: 100%;
    justify-content: space-between; /* Ensure buttons are spaced evenly */
}
.bg-blue {
    flex: 1; /* Ensure buttons take equal space */
    padding: 5px;
    margin: 0 2px; /* Add margin between buttons */
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
    font-size: 1em;
    background-color: var(--primary);
    color: white;
    cursor: pointer;
}
.bg-blue:hover {
    background-color: var(--primary-dark);
    color: black; /* Ensure the text color is black on hover */
}
.bg-blue:disabled,
.bg-blue:disabled:hover {
    background-color: #eb5766;
    color: white;
    cursor: not-allowed;
    opacity: 1; /* Ensure the button remains visible */
}
label {
    flex-grow: 1;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 0;
}
.item_qtt {
    text-align: center;
    width: 70px;
    border-top: 0;
}
.dispo {
    color: #fff;
    padding: 2px 5px;
    background-color: var(--primary);
    font-weight: bold;
    font-size: 0.9em;
    border-radius: 4px;
}
</style>