<template>
	<StatsLayout @search="search" @apply-filters="filter_data">
		<div class="table nonprintable">
		<table>
			<thead>
				<tr>
					<th>produit</th>
					<th>du</th>
					<th>au</th>
					<th class="right">Qtt</th>
					<th class="right">P.A. Total</th>
					<th class="right">P.V. Total</th>
					<th class="right">benefice</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in stats">
					<td>{{ item.produit }}</td>
					<td>{{ datetime(item.du) }}</td>
					<td>{{ datetime(item.au) }}</td>
					<td>{{ item.count }}</td>
					<td class="right">{{ money(item.p_a_t) }} FBu</td>
					<td class="right">{{ money(item.p_v_t) }} FBu</td>
					<td class="right">{{ money(item.p_v_t - item.p_a_t) }} FBu</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<th colspan="3"></th>
					<th class="right">
						{{ stats.reduce((acc, x) => acc + x.count, 0) }}
					</th>
					<th class="right">{{ money(
						stats.reduce((acc, x) => acc + x.p_a_t, 0)
					)}} FBu</th>
					<th class="right">{{ money(
						stats.reduce((acc, x) => acc + x.p_v_t, 0)
					)}} FBu</th>
					<th class="right">{{ money(
						stats.reduce((acc, x) => acc + (x.p_v_t - x.p_a_t), 0)
					)}} FBu</th>
				</tr>
			</tfoot>
		</table>
		</div>
	</StatsLayout>
</template>

<script>
import StatsLayout from "./stats_layout"

export default {
	components: { StatsLayout },
	data() {
		return {
			stats: this.$store.state.stats_prod
		}
	},
	watch: {
		"$store.state.stats_prod"(new_val) {
			this.stats = new_val
		}
	},
	methods: {
		filter_data(arg){
			this.fetchData(arg.date)
		},
		search(keyword) {
			this.stats = this.$store.state.stats_prod.filter(item => {
				const searchKeys = ["produit", "du", "au"];
				for (const key of searchKeys) {
					if (item[key].toLowerCase().includes(keyword.toLowerCase())) {
						return true;
					}
				}
				return false;
			});
		},

		fetchData(date = '') {
			let link = this.url + `/vente/stats/${date ? `?date=${date}` : ''}`;
			axios.get(link, this.headers)
				.then((response) => {
					this.$store.state.stats_prod = response.data
				}).catch((error) => {
					this.displayErrorOrRefreshToken(error, this.fetchData)
				});
		},

		imprimer() {
			let printContent = `
				<table>
					<thead>
						<tr>
							<th>produit</th>
							<th>du</th>
							<th>au</th>
							<th class="right">Qtt</th>
							<th class="right">P.A. Total</th>
							<th class="right">P.V. Total</th>
							<th class="right">benefice</th>
						</tr>
					</thead>
					<tbody>
			`;

			this.stats.forEach(item => {
				printContent += `
					<tr>
						<td>${item.produit}</td>
						<td>${this.datetime(item.du)}</td>
						<td>${this.datetime(item.au)}</td>
						<td>${item.count}</td>
						<td class="right">${this.money(item.p_a_t)} FBu</td>
						<td class="right">${this.money(item.p_v_t)} FBu</td>
						<td class="right">${this.money(item.p_v_t - item.p_a_t)} FBu</td>
					</tr>
				`;
			});

			printContent += `
					</tbody>
					<tfoot>
						<tr>
							<th colspan="4"></th>
							<th class="right">${this.money(this.stats.reduce((acc, x) => acc + x.p_a_t, 0))} FBu</th>
							<th class="right">${this.money(this.stats.reduce((acc, x) => acc + x.p_v_t, 0))} FBu</th>
							<th class="right">${this.money(this.stats.reduce((acc, x) => acc + (x.p_v_t - x.p_a_t), 0))} FBu</th>
						</tr>
					</tfoot>
				</table>
			`;
			const printWindow = window.open('', '', 'width=800,height=600');
			printWindow.document.write('<html><head><title>Statistique des produits</title>');
			printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 5px; text-align: left; } .right { text-align: right; }</style>');
			printWindow.document.write('</head><body>');
			printWindow.document.write(printContent);
			printWindow.document.write('</body></html>');
			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		}
	},
	mounted() {
		if (this.$store.state.stats_prod.length < 1) {
			this.fetchData();
		}
	}
};
</script>

<style scoped>
@media print {
	.nonprintable {
		display: none;
	}
}
</style>
