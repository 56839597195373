<template>
  <div class="pos">
    <center class="nonprintable">
      <div class="sv">
        <input type="text" placeholder="recherche" v-model="keyword">
      </div>   
    </center>
    <div class="contents nonprintable">
      <div class="items" id="recycler">
        <VenteItem v-for="item in produits" :item="item" :key="item.id"/>
      </div>
      <CartView :cart="cart" v-if="details_shown"
        @close="switchDetails(false)"
        @submit="confirmVente"
      />
      <div class="mini-cart" v-else @click="switchDetails(true)">
        Panier ({{ cart.content.length }})
      </div>
    </div>
    <Invoice :commande="fetched"/>
    <DialogVente 
      :active="confirm_vente" 
      @close="confirm_vente=false"
    />
  </div>
</template>
<script>
import VenteItem from "../components/vente_item"
import CartView from "../components/cart"
import DialogVente from "../components/dialog_vente"
import Invoice from "../components/invoice"
export default {
  components: {VenteItem, CartView, DialogVente, Invoice},
  data(){
    return{
      produits:this.$store.state.produits, confirm_vente:false,
      details_shown:true, next:null, to_search:"", fetched:null,
      keyword:"",
    }
  },
  computed:{
    cart(){
      return this.$store.state.cart;
    }
  },
  watch:{
    "$store.state.produits"(new_val){
      this.produits = new_val
    },
    "$store.state.commande":{
      deep:true,
      handler(new_val){
        if(!!new_val){
          this.fetched = new_val
        }
      }
    },
    keyword(new_val){
      this.produits = this.$store.state.produits.filter(x => {
        return x.nom.toLowerCase().includes(new_val.toLowerCase())
      })
    }
  },
  methods:{
    switchDetails(value){
      this.details_shown = value
      setTimeout(this.recalculateColumns, 100)
    },
    confirmVente(){
      this.confirm_vente = true
    },
    fetchData(){
      let link = ""
      let kiosk_id = this.getActiveKiosk().id
      if(!this.next){
        link = this.url+`/produit/?kiosk=${kiosk_id}`;
      } else {
        link = this.next
      }
      axios.get(link, this.headers)
      .then((response) => {
        this.$store.state.produits.push(...response.data.results)
        if(response.data.next.length > 0){
          this.next = response.data.next
          this.fetchData()
        } else {
          this.next = null
        }
      }).catch((error) => {
        this.displayErrorOrRefreshToken(error, this.fetchData)
      });
    },
    search(keywords){
      this.produits = this.$store.state.produits
      this.produits = this.fuzzySearch(this.produits, keywords)
    },
    recalculateColumns(){
      console.log("recalculate columns")
      try {
        let width = recycler.clientWidth;
        let columns = Math.round(width/210)
        recycler.style.gridTemplateColumns = `repeat(${columns}, 1fr)`
      } catch(e) {
        console.log(e);
      }
    },
  },
  mounted(){
    if(this.$store.state.produits.length<1){
      this.fetchData()
    }
    this.recalculateColumns()
    window.addEventListener("resize", this.recalculateColumns);
    if(!this.$store.state.obr_token){
      let data = {
        username: this.active_kiosk.username,
        password: this.active_kiosk.password
      }
      axios.post(this.obr_url+"/login/", JSON.stringify(data))
      .then((response) => {
        this.$store.state.obr_token = response.data.result.token
      }).catch((error) => {
        console.error(error)
        alert("OBR User infos are incorects")
      })
    }
  },
  beforeRouteLeave(to, from, next){
    console.log("no longer tracking resize")
    window.removeEventListener("resize", this.recalculateColumns);
    next()
  }
}
</script>
<style scoped>
.pos {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.contents {
  display: flex;
  flex: 1;
  position: relative;
  gap: 15px;
  padding: 0 15px;
  height: calc(100vh - 60px);
}

.items {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  gap: 15px;
  padding: 15px;
  height: 100%;
  overflow-y: auto;
  align-items: start;
  padding-bottom: 80px; /* Add padding to prevent overlap with mini-cart */
}

/* Ensure proper spacing when cart is open */
.contents:has(.cart-view) .items {
  width: calc(100% - 400px);
  margin-right: 15px;
}

/* Customize scrollbar for better visibility */
.items::-webkit-scrollbar {
  width: 8px;
}

.items::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.items::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 4px;
}

.items::-webkit-scrollbar-thumb:hover {
  background: var(--primary-dark);
}

.mini-cart {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary);
  color: white;
  font-weight: 600;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  cursor: pointer;
  z-index: 100;
}

.sv {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sv input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 250px;
}

/* Add styles for the cart view to ensure proper layout */
:deep(.cart-view) {
  width: 400px;
  min-width: 350px;
  max-width: 100%;
  height: 100%;
  overflow-y: auto;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

:deep(.cart-view .cart-buttons) {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

:deep(.cart-view .cart-buttons button) {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

:deep(.cart-view .cart-buttons button.reset) {
  background: #f8f9fa;
  border: 1px solid #ddd;
  color: #666;
}

:deep(.cart-view .cart-buttons button.submit) {
  background: var(--primary);
  border: 1px solid var(--primary);
  color: white;
}

:deep(.cart-view .cart-buttons button:hover) {
  transform: translateY(-1px);
}

:deep(.cart-view .cart-buttons button.reset:hover) {
  background: #e9ecef;
}

:deep(.cart-view .cart-buttons button.submit:hover) {
  background: var(--primary-dark);
}

@media (max-width: 1024px) {
  .contents:has(.cart-view) .items {
    width: calc(100% - 350px);
  }
}

@media (max-width: 768px) {
  .contents {
    padding: 0 10px;
  }
  
  .items {
    gap: 8px;
    padding: 10px;
  }
  
  .sv input {
    width: 200px;
  }
}
</style>