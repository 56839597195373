<template>
  <div class="invoice-container">
    <header class="invoice-header">
      <div class="left-side">
        <img :src="getActiveKiosk().logo" alt='Hogi Logo'/>
        <section class="invoice-billing">
          <div class="billed-to">
            <p><strong>Facturé à</strong></p>
            <p>{{ commande.client.customer_name }}</p>
            <p>NIF: {{ commande.client.customer_TIN }}</p>
            <p>{{ commande.client.customer_address }}</p>
            <p>Assujetti à la TVA: {{ commande.client.vat_taxpayer === '1' ? 'Oui' : 'Non' }}</p>
          </div>
        </section>
      </div>
      <div class="company-info">
        <h1>Facture</h1>
        <p>{{ getActiveKiosk().nom }}</p>
        <p>{{ getActiveKiosk().tel }} - {{ getActiveKiosk().email }}</p>
        <p> 
          {{ getActiveKiosk().tp_address_avenue }}, 
          {{ getActiveKiosk().tp_address_commune }}, 
          {{ getActiveKiosk().tp_address_quartier }}, 
          {{ getActiveKiosk().tp_address_province }}
        </p>
        <p>R.C: {{ getActiveKiosk().tp_trade_number }} NIF: {{ getActiveKiosk().tp_TIN  }}</p>
        <p>Assujetti à la TVA: {{ getActiveKiosk().tp_type === '1' ? 'Oui' : 'Non' }}</p>
        <p v-if="commande.synced">Invoice Number : {{ invoice_number }}</p>
        <p v-if="commande.synced">Invoice Date : {{ date }}</p>
        <p v-if="commande.synced">Invoice Identifier : {{ invoice_identifier  }}</p>
        <!-- <button @click="getInvoiceFromOBR(commande)">test</button> -->
      </div>
    </header>
    <div class="invoice-details">
      <div>
        <p><strong>Facture N°</strong>: {{commande.id}}</p>
        <p><strong>Date</strong>: {{commande.date}}</p>
      </div>
    </div>

    <section class="invoice-data">
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Qté</th>
            <th>PU</th>
            <th>PVHTVA</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in commande.ventes" :key="item.id" >
            <td>{{ item.produit }}</td>
            <td class="text-center">{{ item.quantite }}</td>
            <td class="text-right">{{ money(item.prix_unitaire) }}</td>
            <td class="text-right">{{ money(item.prix_total)}}</td>
          </tr>
        </tbody>
      </table>
    </section>

    <div class="bottom-section">
      <div class="payment-instructions">
        <h3><strong>Mode de paiement</strong></h3>
        <br>
        <p>1. Paiement par virement bancaire:</p>
        <p>N° de compte: 60115520001 ouvert à BBCI au nom de HOGI</p>
        <p>2. Paiement mobile money:</p>
        <p>Lumicash: +257 69 26 56 16 au nom de Innocent SANGWE</p>
        <br>
      </div>

      <section class="invoice-totals">
        <table>
          <tbody>
            <tr>
              <td>PVT HTVA</td>
              <td>{{ money(commande.prix) }}</td>
            </tr>
            <tr>
              <td>Remise</td>
              <td>0</td>
            </tr>
            <tr>
              <td>TVA</td>
              <td>{{ money(Math.ceil(commande.prix * 0.18)) }}</td>
            </tr>
            <tr class="total-row">
              <th>Total TVAC</th>
              <th>{{ money(Math.ceil(commande.prix + Math.ceil(commande.prix * 0.18))) }}</th>
            </tr>
            <tr class="amount-in-words">
              <td colspan="2"><strong>Nous disons</strong> : {{ convertToWords(Math.ceil(commande.prix + Math.ceil(commande.prix * 0.18))) }} Francs Burundais</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
    <br>
    <hr class="date_foot">
    <p>{{formatToday}}</p>
    <p class="signature">{{ today }}</p>
    <div class="footer-image">
      <img :src="getActiveKiosk().banner" :alt="getActiveKiosk().nom + ' Banner'" v-if="getActiveKiosk().banner" />
    </div>
  </div>
</template>

<script>
export default {
name: "InvoiceComponent",
props: ["commande"],
computed : {
  formatToday() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear();
      return `${day}/${month}/${year}`;
    },
  invoice_number(){
    const year = new Date(this.commande.updated_at).getUTCFullYear();
    return `${this.commande.id}/${year}`
  },
  date(){
    return this.commande.updated_at.replace("T", " ").split(".")[0];
  },
  dateInvoceId(){
    return new Date(this.commande.updated_at).getUTCFullYear() + ('0' + (new Date(this.commande.updated_at).getUTCMonth() + 1)).slice(-2) + ('0' + new Date(this.commande.updated_at).getUTCDate()).slice(-2) + ('0' + new Date(this.commande.updated_at).getUTCHours()).slice(-2) + ('0' + new Date(this.commande.updated_at).getUTCMinutes()).slice(-2) + ('0' + new Date(this.commande.updated_at).getUTCSeconds()).slice(-2);
  },
  invoice_identifier(){
    const tp_TIN = this.getActiveKiosk().tp_TIN;
    const year = new Date(this.commande.updated_at).getUTCFullYear();
    const username = this.getActiveKiosk().username;
    return `${tp_TIN}/${username}/${this.dateInvoceId}/${this.invoice_number}`;
  }
},
methods: {
    convertToWords(number) {
      const units = ['', 'un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf'];
      const teens = ['dix', 'onze', 'douze', 'treize', 'quatorze', 'quinze', 'seize', 'dix-sept', 'dix-huit', 'dix-neuf'];
      const tens = ['', 'dix', 'vingt', 'trente', 'quarante', 'cinquante', 'soixante', 'soixante-dix', 'quatre-vingt', 'quatre-vingt-dix'];
      const scales = ['', 'mille', 'million', 'milliard', 'billion', 'billiard'];

      // Fonction pour gérer les règles spéciales du français
      const handleSpecialCases = (number, scaleIndex) => {
        if (scaleIndex >= 2) { // Pour million et plus
          return number === 1 ? '' : 's';
        }
        return '';
      };

      // Convertir un groupe de 3 chiffres
      const convertGroup = (n) => {
        if (n === 0) return '';
        
        let result = '';
        
        // Gérer les centaines
        const hundreds = Math.floor(n / 100);
        if (hundreds > 0) {
          if (hundreds === 1) {
            result += 'cent ';
          } else {
            result += units[hundreds] + ' cent ';
          }
        }
        
        // Gérer les dizaines et unités
        const remainder = n % 100;
        if (remainder > 0) {
          if (remainder < 10) {
            result += units[remainder];
          } else if (remainder < 20) {
            result += teens[remainder - 10];
          } else {
            const ten = Math.floor(remainder / 10);
            const one = remainder % 10;
            
            if (ten === 7 || ten === 9) {
              const baseNumber = ten === 7 ? 60 : 80;
              result += tens[baseNumber / 10];
              if (one === 1 && ten === 7) {
                result += ' et ' + teens[one];
              } else {
                result += '-' + teens[one];
              }
            } else {
              result += tens[ten];
              if (one > 0) {
                if (one === 1 && ten !== 8) {
                  result += ' et ';
                } else {
                  result += '-';
                }
                result += units[one];
              } else if (ten === 8) {
                result += 's';
              }
            }
          }
        }
        
        return result.trim();
      };

      // Fonction principale
      const convert = (number) => {
        if (number === 0) return 'zéro';
        
        const parts = [];
        let num = Math.round(number); // Arrondir le nombre
        
        // Diviser le nombre en groupes de 3 chiffres
        while (num > 0) {
          parts.push(num % 1000);
          num = Math.floor(num / 1000);
        }
        
        let result = '';
        
        // Traiter chaque groupe
        for (let i = parts.length - 1; i >= 0; i--) {
          if (parts[i] !== 0) {
            let group = convertGroup(parts[i]);
            
            // Ajouter l'échelle (million, milliard, etc.)
            if (i > 0) {
              if (parts[i] === 1 && i === 1) {
                result += 'mille ';
              } else {
                result += group + ' ' + scales[i] + handleSpecialCases(parts[i], i) + ' ';
              }
            } else {
              result += group;
            }
          }
        }
        
        return result.trim();
      };

      return convert(number);
    }
  }
};
</script>

<style scoped>
/* body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f8f8;
} */
 *{
  box-sizing: border-box;
 }
.date_foot{
  width: 150px;
}
.invoice-container {
  position: fixed;
  font-family: Arial, sans-serif;
  color: black;
  width: 20.5cm !important; 
  /* max-width: 100%; */
  height: 29cm;
  margin: 0 auto;
  /* border: 1px solid #ddd; */
  padding: 40px;
  background: white;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  z-index: 10000;
}

p {
  margin: 0 0 5px;
  font-size: 13px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-side img {
  width: 150px;
  margin-bottom: 20px;
}

.company-info {
  text-align: right;
}

.invoice-details {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.invoice-details p {
  display: flex;
  justify-content: space-between;
  width: 250px;
}

.invoice-data table,
.invoice-totals table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-data th,
.invoice-data td,
.invoice-totals td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.invoice-data th {
  background-color: #70ad47;
  color: white;
}

.invoice-data tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.invoice-totals td {
  text-align: right;
  font-weight: bold;
}

.invoice-totals th {
  text-align: right;
  font-size: 16px;
}

.signature {
margin-top: 40px; 
text-align: left;
text-decoration: overline;
font-size: 14px;
position: relative; 
z-index: 2;
}
.footer-image {
position: relative;
margin-top: 60px; 
text-align: center;
z-index: 1;
}
.footer-image img {
width: 100%; 
max-width: 800px;
height: auto;
}
@media print {
  .invoice-container{
    position: fixed !important;
    top: -30px !important;
    left: -20px !important;
  }
}

.invoice-data table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  table-layout: fixed;
}

.invoice-data th,
.invoice-data td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* Set specific widths for each column */
.invoice-data th:nth-child(1),
.invoice-data td:nth-child(1) {
  width: 40%;
}

.invoice-data th:nth-child(2),
.invoice-data td:nth-child(2) {
  width: 15%;
}

.invoice-data th:nth-child(3),
.invoice-data td:nth-child(3),
.invoice-data th:nth-child(4),
.invoice-data td:nth-child(4) {
  width: 22.5%;
}

.invoice-totals {
  width: 45%; /* Adjust to match the last two columns of invoice-data */
  margin-left: auto; /* Push to the right */
}

.invoice-totals table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.invoice-totals td:first-child,
.invoice-totals th:first-child {
  width: 50%;
}

.invoice-totals td:last-child,
.invoice-totals th:last-child {
  width: 50%;
}

/* Ensure consistent text alignment */
.text-right,
.invoice-totals td,
.invoice-totals th,
.invoice-data td:nth-child(3),
.invoice-data td:nth-child(4) {
  text-align: right;
}

.bottom-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 40px;
}

.payment-instructions {
  flex: 1;
  font-size: 13px;
}

.payment-instructions h4 {
  color: #70ad47;
  margin-bottom: 10px;
}

.invoice-totals {
  width: 300px;
}

.invoice-totals table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-totals td,
.invoice-totals th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: right;
}

.invoice-totals .total-row {
  background-color: #f8f8f8;
}

.invoice-totals .total-row th {
  font-size: 14px;
  color: #333;
}

@media print {
  .bottom-section {
    page-break-inside: avoid;
  }
}

.amount-in-words td {
  text-align: left !important;
  font-style: italic;
  padding: 10px 8px;
  font-size: 12px;
  color: #444;
  border-top: none;
}
</style>
