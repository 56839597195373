<template>
	<StatsLayout>
	  <div class="option">
		<label for="dette">Dettes</label>
		<input type="checkbox" id="dette" v-model="only_dettes" />
	  </div>
	  <div class="table nonprintable">
		<table>
		  <thead>
			<tr>
			  <th></th>
			  <th>No.</th>
			  <th>Date</th>
			  <th>Caissier</th>
			  <th>Client</th>
			  <th>Message OBR</th>
			  <th class="right">TVA</th>
			  <th class="right">Somme</th>
			  <th class="right">Reste</th>
			  <th class="right">Bénéfice</th>
			  <th>Options</th>
			</tr>
		  </thead>
		  <tbody>
			<tr v-for="item in commandes" :key="item.id" :class="{ syncing: item.synced }">
			  <td class="sync">
				<fa v-if="!item.synced" icon="exclamation-triangle" />
			  </td>
			  <td>{{ item.id }}</td>
			  <td>{{ datetime(item.updated_at) }}</td>
			  <td>{{ item.user }}</td>
			  <td>{{ getUser(item) }}</td>
			  <td :class="{
					'text-green-500': item.message_obr === 'success',
					'text-red-500': item.message_obr === 'danger',
				}">
				{{ item.message_obr || '-' }}
			  </td>
			  <td class="right">{{ money(Math.ceil(item.tax_amount)) }} FBU</td>
			  <td class="right">{{ money(Math.ceil(getInvoiceTotal(item))) }} FBU</td>
			  <td class="right">{{ money(Math.ceil(item.prix - item.payee)) }} FBU</td>
			  <td class="right">{{ money(Math.ceil(item.prix - item.prix_achat)) }} FBU</td>
			  <td class="left">
				<button @click="showDetails(item)">Details</button>
				<button class="red" @click="item.synced ? askForMotif(item) : deleteOrder(item)">
				  {{ item.synced ? 'Annuler' : 'Supprimer' }}
				</button>
				<button v-if="item.payee < item.prix" @click="payer(item)">Payer</button>
				<button v-if="!item.synced && item.message_obr !== 'success'" @click="SynchroniserOBR(item)">
				  Synchroniser
				</button>
			  </td>
			</tr>
		  </tbody>
		  <tfoot>
			<tr>
			  <th colspan="6"></th>
			  <th class="right">
				{{ money(commandes.reduce((acc, x) => acc + x.tax_amount, 0)) }} FBu
			  </th>
			  <th class="right">
				{{ money(commandes.reduce((acc, x) => acc + x.prix, 0)) }} FBu
			  </th>
			  <th class="right">
				{{ money(commandes.reduce((acc, x) => acc + (x.prix - x.payee), 0)) }} FBu
			  </th>
			  <th class="right">
				{{ money(commandes.reduce((acc, x) => acc + (x.prix - x.prix_achat), 0)) }} FBu
			  </th>
			  <th colspan="3"></th>
			</tr>
		  </tfoot>
		</table>
	  </div>
	  <DialogVentes :active="ventes_shown" :commande="active_command" @close="close" />
	  <DialogPayment :active="payment_shown" :commande="active_command" @close="close" />
	  <DialogMotif :active="motifDialogActive" @close="closeMotifDialog" @submit="handleMotifSubmit" />
	</StatsLayout>
  </template>
  
  <script>
  import StatsLayout from "./stats_layout";
  import DialogVentes from "../components/dialog_ventes";
  import DialogPayment from "../components/dialog_payment";
  import DialogMotif from "../components/dialog_cancel_invoice.vue"; 
  
  export default {
	components: { StatsLayout, DialogVentes, DialogPayment, DialogMotif },
	data() {
	  return {
		obr_token:this.$store.state.obr_token,
		ventes_shown: false,
		active_command: null,
		next: null,
		commandes: this.$store.state.commandes,
		payment_shown: false,
		only_dettes: false,
		unsynceds: [],
		cancel_motif: "", 
		motifDialogActive: false, 
		client: {
			nom: "",
			tel: "",
			invoice_type: "",
			vat_taxpayer: "",
			ct_taxpayer: "",
			tl_taxpayer: "",
			payment_type: "",
			invoice_currency: "",
			customer_name: "",
			customer_TIN: "",
			customer_address: "",
			vat_customer_payer: ""
		},
		dette_p:null,
		is_client_new:false,
		keyword:"",
	  };
	},
	watch: {
	  "$store.state.commandes"(new_val) {
		this.commandes = new_val;
		this.unsynceds = new_val.filter(x => !x.synced);
	  },
	  only_dettes(new_val) {
		this.commandes = new_val ? this.$store.state.commandes.filter(x => x.prix > x.payee) : this.$store.state.commandes;
	  }
	},
	methods: {
	  close() {
		this.ventes_shown = false;
		this.active_command = null;
		this.payment_shown = false;
	  },
	  closeMotifDialog() {
		this.motifDialogActive = false;
	  },
	  handleMotifSubmit(motif) {
		this.cancel_motif = motif;
		this.cancelInvoice(this.active_command);
	  },
	  getUser(item) {
		return typeof item.client === "string" ? item.client : `${item.client.nom} ${item.client.tel}`;
	  },
	  showDetails(commande) {
		this.ventes_shown = true;
		this.active_command = commande;
	  },
	  payer(commande) {
		this.payment_shown = true;
		this.active_command = commande;
	  },

	  async SynchroniserOBR(item) {
			try {
				let dateObj = new Date(item.updated_at);
				let str_date = dateObj.getUTCFullYear() +
					('0' + (dateObj.getUTCMonth() + 1)).slice(-2) +
					('0' + dateObj.getUTCDate()).slice(-2) +
					('0' + dateObj.getUTCHours()).slice(-2) +
					('0' + dateObj.getUTCMinutes()).slice(-2) +
					('0' + dateObj.getUTCSeconds()).slice(-2);

				let kiosk = this.getActiveKiosk();
				let client = item.client;
				let commandeSync = this.commandes.find(cmd => cmd.id === item.id);

				if (!commandeSync) {
					console.error("Commande introuvable");
					return;
				}

				let items = commandeSync.ventes.map(v => {
					let prix = parseFloat(v.item_price) || 0;
					let quantite = parseInt(v.item_quantity) || 0;
					let tva = (prix * 0.18).toFixed(2);
					let prix_nvat = prix.toFixed(2);
					let prix_wvat = (prix + parseFloat(tva)).toFixed(2);
					let total_amount = ((prix + parseFloat(tva)) * quantite).toFixed(2);

					return {
						"item_designation": v.item_designation || "Article sans nom",
						"item_quantity": quantite,
						"item_price": prix_nvat,
						"item_ct": v.item_ct || "0",
						"item_tl": v.item_tl || "0",
						"item_ott_tax": v.item_ott_tax || "0",
						"item_tsce_tax": v.item_tsce_tax || "0",
						"item_price_nvat": prix_nvat,
						"vat": tva,
						"item_price_wvat": prix_wvat,
						"item_total_amount": total_amount,
						"item_category": v.item_category || "", 
						"item_code": v.item_code || ""
					};
				});

				let invoice_number = `${commandeSync.id}`;
				let invoice_identifier = `${kiosk.tp_TIN}/${kiosk.username}/${str_date}/${invoice_number}`;
				let invoice_date = dateObj.toISOString().replace("T", " ").split(".")[0];

				let invoice = {
					"invoice_number": invoice_number,
					"invoice_date": invoice_date,
					"invoice_type": client.invoice_type,
					"tp_type": kiosk.tp_type,
					"tp_name": kiosk.tp_name,
					"tp_TIN": kiosk.tp_TIN,
					"tp_trade_number": kiosk.tp_trade_number,
					"tp_postal_number": kiosk.tp_postal_number,
					"tp_phone_number": kiosk.tp_phone_number,
					"tp_address_province": kiosk.tp_address_province,
					"tp_address_commune": kiosk.tp_address_commune,
					"tp_address_quartier": kiosk.tp_address_quartier,
					"tp_address_avenue": kiosk.tp_address_avenue,
					"tp_address_number": kiosk.tp_address_number || "",
					"vat_taxpayer": client.vat_taxpayer,
					"ct_taxpayer": client.ct_taxpayer,
					"tl_taxpayer": client.tl_taxpayer,
					"tp_fiscal_center": kiosk.tp_fiscal_center,
					"tp_activity_sector": kiosk.tp_activity_sector,
					"tp_legal_form": kiosk.tp_legal_form,
					"payment_type": client.payment_type,
					"invoice_currency": client.invoice_currency,
					"customer_name": client.customer_name,
					"customer_TIN": client.customer_TIN,
					"customer_address": client.customer_address,
					"vat_customer_payer": client.vat_customer_payer,
					"cancelled_invoice_ref": "",
					"invoice_ref": "",
					"cn_motif": "",
					"invoice_identifier": invoice_identifier,
					"invoice_items": items
				};

				let response = await axios.post(this.obr_url + "/addInvoice_confirm/", invoice, this.obr_headers);

				if (!response.data.success) {
					item.message_obr = `${response.data.msg}`;
					item.synced = false;

					this.$store.state.alert = {
						type: "danger",
						message: this.cleanString(response.data.msg)
					};

					console.log(response.data.msg);
				} else {
					item.synced = true;
					item.message_obr = response.data.msg;

					this.$store.state.alert = {
						type: "success",
						message: this.cleanString(response.data.msg)
					};

					console.log("Facture envoyée avec succès :", item);
				}

			} catch (error) {
				console.error("Erreur lors de la synchronisation OBR :", error);
				this.displayErrorOrRefreshOBR(error, () => this.SynchroniserOBR(item));
			}
		},

	  askForMotif(item) {
		this.motifDialogActive = true; 
		this.active_command = item;
	  },
	  cancelInvoice(item) {
			const tp_TIN = this.getActiveKiosk().tp_TIN;
			const username = this.getActiveKiosk().username;
			const date = item.updated_at.replace(/[-:]/g, "").replace("T", "").slice(0, 14);
			const year = new Date(item.updated_at).getUTCFullYear();
			const numero_invoice = item.id;
			const invoice_identifier = `${tp_TIN}/${username}/${date}/${numero_invoice}`;

			const invoiceData = {
				invoice_identifier: invoice_identifier,
				cn_motif: this.cancel_motif,
			};

			axios
				.post(`${this.obr_url}/cancelInvoice/`, invoiceData, this.obr_headers)
				.then((response) => {
					if (!response.data.success) {
						if (response.data.msg.includes("API est manquante.")) {
							this.refreshOBR(() => this.cancelInvoice(item));
							this.$store.state.alert = {
								type: "warning",
								message: "Impossible d'annuler la facture sur OBR pour le moment. Tentative de reconnexion...",
							};
						} else {
							this.$store.state.alert = {
								type: "danger",
								message: this.cleanString(response.data.msg),
							};
						}
					} else {
						this.$store.state.alert = {
							type: "success",
							message: this.cleanString(response.data.msg),
						};
						axios
							.delete(`${this.url}/commande/${item.id}/`, this.headers)
							.then(() => {
								this.commandes = this.commandes.filter(cmd => cmd.id !== item.id);
								this.$store.state.alert = {
									type: "success",
									message: "Facture supprimée avec succès!",
								};
								location.reload();
							})
							.catch((error) => {
								this.$store.state.alert = {
									type: "danger",
									message: "Facture annulée sur OBR, mais impossible de supprimer la commande.",
								};
							});
					}
				})
				.catch((error) => {
					this.displayErrorOrRefreshOBR(error, () => this.cancelInvoice(item), null);
					console.error("Erreur réseau ou problème avec l'API OBR :", error);
					this.$store.state.alert = {
						type: "danger",
						message: this.cleanString(response.data.msg),
					};
				});
		},

		deleteOrder(item) {
			if (window.confirm("Voulez-vous vraiment supprimer cette commande ?")) {
				this.refreshOBR();
				axios
					.delete(`${this.url}/commande/${item.id}/`, this.headers)
					.then(() => {
						this.commandes = this.commandes.filter(cmd => cmd.id !== item.id);
						this.$store.state.alert = {
							type: "success",
							message: "Commande supprimée avec succès.",
						};
					})
					.catch((error) => {
						console.error("Erreur lors de la suppression de la commande :", error);
						this.$store.state.alert = {
							type: "danger",
							message: "Erreur lors de la suppression de la commande.",
						};
					});
			}
		},

	  fetchData() {
		let link = "";
		let kiosk_id = this.getActiveKiosk().id;
		if (!this.next) {
		  link = this.url + `/commande/?kiosk=${kiosk_id}`;
		} else {
		  link = this.next;
		}
		axios.get(link, this.headers)
		  .then((response) => {
			this.$store.state.commandes.push(...response.data.results);
			if (response.data.next.length > 0) {
			  this.next = response.data.next;
			  this.fetchData();
			} else {
			  this.next = null;
			}
		  }).catch((error) => {
			this.displayErrorOrRefreshToken(error, this.fetchData);
		  });
	  },

	  getInvoiceTotal(invoice) {
		if (!invoice.ventes || invoice.ventes.length === 0) {
		  return 0; // Return 0 if there are no products in the invoice
		}
		return invoice.ventes.reduce((total, product) => {
		  return total + Math.ceil(product.item_total_amount);
		}, 0);
	  },
	},
	mounted() {
	  if (this.$store.state.commandes.length < 1) {
		this.fetchData();
	  }
	}
  };
  </script>
  
  <style scoped>
  .option {
	display: flex;
	justify-content: flex-end;
	align-items: center;
  }
  #dette {
	margin-left: 5px;
  }
  .table {
	height: calc(100vh - 125px);
  }
  .sync {
	font-size: .7em;
	padding: 0;
	color: #750;
  }
  .sync_button {
	padding: 2px 10px;
	margin: 0 0 0 10px;
.text-gray-500 {
    color: #6b7280 !important;
}   color: #10b981 !important; 
}
  </style>
.text-red-500 {
    color: #ef4444 !important; 
}

.text-gray-500 {
    color: #6b7280 !important;
}
</style>
